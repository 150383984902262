import { createRouter, createWebHistory } from 'vue-router'
import Layout from '@/layout'
import { getUserLoginInfo } from '@/utils/auth'

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/index')
  },
  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: () => import('@/views/dashboard/index'),
        meta: { needAuth: true }
      }
    ]
  },
  {
    path: '/change_password',
    component: Layout,
    children: [
      {
        path: '',
        name: 'ChangePassword',
        component: () => import('@/views/change-password/index'),
        meta: { needAuth: true }
      }
    ]
  },
  {
    path: '/banner',
    component: Layout,
    children: [
      {
        path: '',
        name: 'Banner',
        component: () => import('@/views/banner/index'),
        meta: { needAuth: true }
      }
    ]
  },
  {
    path: '/course',
    component: Layout,
    children: [
      {
        path: 'course_list',
        name: 'CourseList',
        component: () => import('@/views/course/index'),
        meta: { needAuth: true }
      },
      {
        path: 'lesson',
        name: 'Lesson',
        component: () => import('@/views/lesson/index'),
        meta: { needAuth: true }
      }
    ]
  },
  {
    path: '/online_course',
    component: Layout,
    children: [
      {
        path: '',
        name: 'OnlineCourse',
        component: () => import('@/views/online-course/index'),
        meta: { needAuth: true }
      }
    ]
  },
  {
    path: '/registration_record',
    component: Layout,
    children: [
      {
        path: '',
        name: 'RegistrationRecord',
        component: () => import('@/views/registration-record/index'),
        meta: { needAuth: true }
      }
    ]
  },
  {
    path: '/teacher',
    component: Layout,
    children: [
      {
        path: '',
        name: 'Teacher',
        component: () => import('@/views/teacher/index'),
        meta: { needAuth: true }
      }
    ]
  },
  {
    path: '/image_category',
    component: Layout,
    children: [
      {
        path: '',
        name: 'ImageCategory',
        component: () => import('@/views/image-category/index'),
        meta: { needAuth: true }
      }
    ]
  },
  {
    path: '/image',
    component: Layout,
    children: [
      {
        path: '',
        name: 'Image',
        component: () => import('@/views/image/index'),
        meta: { needAuth: true }
      }
    ]
  },
  {
    path: '/users',
    component: Layout,
    children: [
      {
        path: '',
        name: 'Users',
        component: () => import('@/views/user/index'),
        meta: { needAuth: true }
      }
    ]
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: () => import('@/views/error-page/404')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const userLoginInfo = getUserLoginInfo()
  if (!to.meta.needAuth) {
    next()
  } else {
    if (userLoginInfo) {
      next()
    } else {
      next({
        path: '/login',
        query: {
          redirect: to.fullPath
        }
      })
    }
  }
})

export default router

<template>
  <section id="app-main">
    <router-view :key="key"/>
  </section>
</template>

<script>
export default {
  name: 'AppMain',
  computed: {
    key () {
      return this.$route.fullPath
    }
  }
}
</script>

<style scoped>
#app-main {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}
</style>

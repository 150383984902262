import storage from '@/storage'
import global from '@/utils/global'

const UserLoginInfoKey = 'user_login_info'

export function getUserLoginInfo () {
  return storage.get(UserLoginInfoKey)
}

export function setUserLoginInfo (userLoginInfo) {
  return storage.set(UserLoginInfoKey, userLoginInfo, global.defaultStorageExpire)
}

export function removeUserLoginInfo () {
  return storage.remove(UserLoginInfoKey)
}

export function getUserAccount () {
  const userLoginInfo = storage.get(UserLoginInfoKey)
  if (userLoginInfo === null) {
    return null
  }
  return userLoginInfo.account
}

export function getAccessToken () {
  const userLoginInfo = storage.get(UserLoginInfoKey)
  if (userLoginInfo === null) {
    return null
  }
  return userLoginInfo.accessToken
}

export function authCheck () {
  return storage.get(UserLoginInfoKey) !== null
}

export function isSuperAdmin () {
  const userLoginInfo = storage.get(UserLoginInfoKey)
  if (userLoginInfo === null) {
    return false
  }
  return userLoginInfo.role === 0
}

<template>
  <div id="header">
    <hamburger id="hamburger-container" :is-active="sidebar.opened" class="hamburger-container"
               @toggleClick="toggleSideBar"/>
    <div class="right-menu">
      <el-dropdown trigger="click" class="avatar-container">
        <div class="avatar-wrapper">
          <svg-icon icon-class="user" class="user-avatar"></svg-icon>
          <svg-icon icon-class="drop-down" class="drop-down-bottom"></svg-icon>
        </div>
        <template #dropdown>
          <el-dropdown-menu>
            <router-link to="/">
              <el-dropdown-item>Dashboard</el-dropdown-item>
            </router-link>
            <router-link to="/change_password">
              <el-dropdown-item>修改密码</el-dropdown-item>
            </router-link>
            <el-dropdown-item divided @click="logout">
              <span style="display:block;">退出登录</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Hamburger from '@/components/Hamburger'
import { logout } from '@/api/login'
import { removeUserLoginInfo } from '@/utils/auth'

export default {
  name: 'Header',
  components: { Hamburger },
  computed: {
    ...mapGetters([
      'sidebar'
    ])
  },
  methods: {
    toggleSideBar () {
      this.$store.dispatch('app/toggleSideBar')
    },
    logout () {
      logout().then(res => {
        removeUserLoginInfo()
        this.$router.push(`/login?redirect=${this.$route.fullPath}`)
      }).catch()
    }
  }
}
</script>

<style lang="scss" scoped>
#header {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, .08);
}

.right-menu {
  float: right;
  height: 100%;
  line-height: 50px;
  display: flex;
  align-items: center;
}

.hamburger-container {
  line-height: 46px;
  height: 100%;
  float: left;
  cursor: pointer;
  transition: background .3s;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    background: rgba(0, 0, 0, .025)
  }
}

.avatar-container {
  .avatar-wrapper {
    margin-right: 30px;

    .user-avatar {
      cursor: pointer;
      font-size: 25px;
    }

    .drop-down-bottom {
      cursor: pointer;
      font-size: 18px;
    }
  }
}
</style>

function getWebDomain () {
  return document.location.protocol + '//' + document.domain + (document.location.port ? ':' + document.location.port : '')
}

const defaultStorageExpire = 3600

export default {
  getWebDomain,
  defaultStorageExpire
}

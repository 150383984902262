<template>
  <el-submenu v-if="item.children" ref="subMenu" :index="item.path.toString()" popper-append-to-body>
    <template #title>
      <svg-icon v-if="item.icon" :icon-class="item.icon"/>
      <span>{{ item.title }}</span>
    </template>
    <sidebar-item
      v-for="child in item.children"
      :key="child.path.toString()"
      :item="child"
      :base-path="child.path.toString()"
    />
  </el-submenu>
  <el-menu-item v-else :index="item.path.toString()">
    <svg-icon v-if="item.icon" :icon-class="item.icon"/>
    <template #title>{{ item.title }}</template>
  </el-menu-item>
</template>

<script>

export default {
  name: 'SidebarItem',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {}
}
</script>

<style scoped>
.svg-icon {
  margin-right: 16px;
}
</style>

<template>
  <div id="layout">
    <el-container>
      <el-aside :class="classObj">
        <Sidebar/>
      </el-aside>
      <el-container>
        <el-header>
          <Header/>
        </el-header>
        <el-main>
          <AppMain/>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>

import AppMain from '@/layout/components/AppMain'
import Header from '@/layout/components/Header'
import Sidebar from '@/layout/components/Sidebar'
import { mapState } from 'vuex'

export default {
  name: 'Layout',
  components: {
    Sidebar,
    Header,
    AppMain
  },
  computed: {
    ...mapState({
      sidebar: state => state.app.sidebar
    }),
    classObj () {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#layout {
  min-height: 100vh;
}
</style>

<template>
  <div class="sidebar-container">
    <logo v-if="showLogo" :collapse="isCollapse"/>
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        class="sidebar-menu"
        :default-active="$route.fullPath"
        :collapse="isCollapse"
        background-color="#304156"
        text-color="#bfcbd9"
        :unique-opened="true"
        active-text-color="#409EFF"
        :collapse-transition="false"
        mode="vertical"
        router
      >
        <sidebar-item v-for="menu in menuList" :key="menu.path" :item="menu" :base-path="menu.path"/>
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Logo from './Logo'
import SidebarItem from './SidebarItem'

export default {
  components: {
    SidebarItem,
    Logo
  },
  data () {
    return {
      menuList: [
        {
          title: 'Dashboard',
          path: '/dashboard',
          icon: 'dashboard'
        },
        {
          title: 'Banner',
          path: '/banner',
          icon: 'banner'
        },
        {
          title: 'Course',
          path: '/course/course_list',
          icon: 'course'
        },
        {
          title: 'OnlineCourse',
          path: '/online_course',
          icon: 'online-course'
        },
        {
          title: 'RegistrationRecord',
          path: '/registration_record',
          icon: 'registration-record'
        },
        {
          title: 'Teacher',
          path: '/teacher',
          icon: 'teacher'
        },
        {
          title: 'ImageCategory',
          path: '/image_category',
          icon: 'image-category'
        },
        {
          title: 'Image',
          path: '/image',
          icon: 'image'
        },
        {
          title: 'Users',
          path: '/users',
          icon: 'users'
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'sidebar'
    ]),
    routes () {
      return this.$router.options.routes
    },
    showLogo () {
      return this.$store.state.settings.sidebarLogo
    },
    isCollapse () {
      return !this.sidebar.opened
    }
  }
}
</script>

<style scoped>
.sidebar-container:not(.el-menu--collapse) {
  -webkit-transition: width 0.28s;
  /* transition: width 0.28s; */
  width: 210px !important;
  background-color: #304156;
  height: 100%;
  min-height: 100vh;
  overflow: hidden;
}
</style>

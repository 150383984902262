import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/lib/theme-chalk/index.css'
import 'element-plus/lib/theme-chalk/display.css'
import registerSvgIcon from './icons' // svg-icon
import '@/styles/index.scss' // global css
import store from './store'
import customGlobal from './utils/global'

const app = createApp(App)
app.use(router)
  .use(ElementPlus)
  .use(store)
  .mount('#app')

app.config.globalProperties.GLOBAL = customGlobal

registerSvgIcon(app)

import axios from 'axios'
import { ElMessage } from 'element-plus'
import { getAccessToken, removeUserLoginInfo } from '@/utils/auth'
import router from '@/router'

const Axios = axios.create({
  baseURL: process.env.VUE_APP_API_HOST,
  timeout: 20000,
  headers: {
    'Content-Type': 'application/json'
  }
})

Axios.interceptors.request.use((config) => {
  config.headers['Access-Token'] = getAccessToken()
  return config
})

Axios.interceptors.response.use(
  response => {
    if (response.data) {
      if (response.data.code === 0) {
        return new Promise((resolve) => {
          resolve(response.data)
        })
      } else {
        ElMessage.error(response.data.message)
        return Promise.reject(response.data.message)
      }
    }
  },
  error => {
    let errorMsg = error
    if (error.response) {
      if (error.response.status === 401) {
        removeUserLoginInfo()
        router.push('/login')
      }
      errorMsg = error.response.data.message ? error.response.data.message : error.response.data
    }
    if (typeof errorMsg === 'string') {
      errorMsg = errorMsg.substr(0, 300)
    }
    ElMessage.error(errorMsg)
    return Promise.reject(errorMsg)
  }
)
export default Axios

const storage = {
  set (key, value, expired = 0) {
    let formatData
    if (expired) {
      const expiredTimestamp = parseInt(Date.parse(new Date()) / 1000) + parseInt(expired)
      formatData = JSON.stringify({
        data: value,
        expiredAt: expiredTimestamp
      })
    } else {
      formatData = JSON.stringify({ data: value })
    }
    localStorage.setItem(key, formatData)
  },
  get (key) {
    const storageValue = localStorage.getItem(key)
    if (storageValue === null) {
      return null
    }
    const formatData = JSON.parse(storageValue)
    if (formatData.data) {
      if (formatData.expiredAt) {
        if (parseInt(formatData.expiredAt) <= parseInt(Date.parse(new Date()) / 1000)) {
          // expired
          this.remove(key)
          return null
        } else {
          return formatData.data
        }
      } else {
        return formatData.data
      }
    }
    return null
  },
  remove (key) {
    localStorage.removeItem(key)
  }
}

export default storage
